import React from "react";
import PropTypes from "prop-types";

import styles from "./banner.module.scss";

const Banner = ({ heading, description, background, alignment }) => {
  return (
    <section
      className={`bp-alternative bp-background ${styles.section}`}
      style={{
        backgroundImage: `url(${background})`
      }}
    >
      <div className="bp-opaque-background" />
      <div className="bp-container">
        <div className={`${styles.container} ${styles[alignment]}`}>
          <h1>{heading}</h1>
          <p className={`bp-accent ${styles.description}`}>{description}</p>
        </div>
      </div>
    </section>
  );
};

Banner.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  background: PropTypes.string,
  alignment: PropTypes.oneOf(["left", "right", "center"])
};

Banner.defaultProps = {
  background: "",
  alignment: "center"
};

export default Banner;
