import HistoryImg2012 from "../src/assets/photos/history-2012.jpg";
import HistoryImg2013 from "../src/assets/photos/history-2013.jpg";
import HistoryImg2014 from "../src/assets/photos/history-2014.jpg";
import HistoryImg2015 from "../src/assets/photos/history-2015.jpg";
import HistoryImg2016 from "../src/assets/photos/history-2016.jpg";
import HistoryImg2019 from "../src/assets/photos/history-2019.jpg";

export const HISTORY = [
  {
    year: 2012,
    key: "2012",
    photos: [HistoryImg2012]
  },
  {
    year: 2013,
    key: "2013",
    photos: [HistoryImg2013]
  },
  {
    year: 2014,
    key: "2014",
    photos: [HistoryImg2014]
  },
  {
    year: 2015,
    key: "2015",
    photos: [HistoryImg2015]
  },
  {
    year: 2016,
    key: "2016",
    photos: [HistoryImg2016]
  },
  {
    year: 2019,
    key: "2019",
    photos: [HistoryImg2019]
  },
  {
    year: 2020,
    key: "2020",
    photos: []
  },
  {
    year: 2023,
    key: "2023",
    photos: []
  }
];
