import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { HISTORY } from "../../../content/history";

import BannerBackground from "../../assets/photos/banner-history.jpg";

import { useTranslations } from "../../hooks/translations";
import SEO from "../../components/seo";
import JoinUs from "../../components/about/join-us";
import Banner from "../../components/banner";

import styles from "./history.module.scss";

export const query = graphql`
  query HistoryQuery($locale: String!) {
    translations(locale: $locale) {
      pages {
        about {
          history
        }
      }
      joinUs
      ...LayoutTranslations
    }
  }
`;

const HistorySection = ({ year, date, text, photos }) => (
  <li className={styles.historySection}>
    <time dateTime={year}>{date}</time>
    <p>{text}</p>
    <div className={styles.photos}>
      {photos.map(photo => (
        <img key={photo} src={photo} alt="" className={styles.photo} />
      ))}
    </div>
    <div className={styles.point} />
  </li>
);

HistorySection.propTypes = {
  year: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  photos: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
};

const HistoryPage = () => {
  const t = useTranslations("pages.about.history");

  return (
    <>
      <SEO title={t.title} description={t.metaDescription} />

      <Banner
        heading={t.headingTop}
        description={t.descriptionTop}
        background={BannerBackground}
        alignment="left"
      />

      <section>
        <h1>{t.heading}</h1>
        <div className={`bp-container ${styles.container}`}>
          <ul className={styles.history}>
            {HISTORY.map(({ key, year, photos }) => (
              <HistorySection
                key={key}
                year={year}
                date={`${year} ${t.y}`}
                text={t.timeline[key]}
                photos={photos}
              />
            ))}
          </ul>
        </div>
      </section>

      <JoinUs heading={t.joinUsHeading} description={t.joinUsDescription} />
    </>
  );
};

export default HistoryPage;
